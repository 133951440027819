import {
  FOG_100,
  OwnUpBody,
  OwnUpDefaultInfoNotification,
  OwnUpSmallSubheadlineRegular
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { LayoutWithAppWrapper } from '../components/layout';
import { ContentWrapper, PageWrapper } from '../components/layout/wrappers';
import { PageTitle } from '../components/typography';

const partners = [
  'Allied Mortgage Group',
  'American Pacific Mortgage',
  'Angel Oak Mortgage Solutions',
  'Anywhere Real Estate',
  'Assurance Financial',
  'Bank of Newport',
  'Bethpage Federal Credit Union',
  'Better Mortgage',
  'Central Bank and Trust',
  'City First Mortgage Services, LLC',
  'Clear 2 Close',
  'ClickStart Mortgage LLC',
  'CrossCountry Mortgage, LLC',
  'District Lending',
  'E Mortgage Capital, Inc',
  'Eclick Lending',
  'Element Mortgage',
  'Farmers Bank & Trust',
  'Filo Mortgage',
  'First Federal Bank Mortgage Lenders',
  'First Federal Bank of Florida',
  'Forte Financial',
  'GRMC Lending',
  'Guaranteed Rate',
  'Harvard Employees Credit Union',
  'ICD Mortgage',
  'Interfirst',
  'LeaderOne Financial',
  'LoanFlight Lending LLC',
  'Loan Lock',
  'McGlone/Homestead Funding',
  'Mission Loans',
  'Mortgage Investors Group',
  'Movoto, Inc.',
  'Movoto, LLC',
  'Movoto Home Loans',
  'Real estate brokers and agents participating in the Movoto Select Network and Movoto Pro+ (real estate agent referral networks)',
  'Mutual of Omaha',
  'Nations Lending',
  'NBKC Bank',
  'New American Funding',
  'NJ Lenders',
  'North American Savings Bank',
  'Northpointe Bank',
  'Novus Home Mortgage',
  'OJO Labs Inc.',
  'OL Financial LLC',
  'Owning',
  'Primary Residential Mortgage',
  'Prosperity Home Mortgage',
  'Radius Financial Group',
  'Reliance First Capital, LLC',
  'Rocket Mortgage',
  'Rockland Federal Credit Union',
  'Sammamish Mortgage',
  'Simpler Home Loans',
  'Society Mortgage',
  'SoFi',
  'Strong Home Mortgage',
  'Success Mortgage Partners',
  'The Mortgage Company',
  'Tremont Credit Union',
  'Valley View Home Loans',
  'Veterans First Mortgage',
  'Yield Mortgage Powered By Ixonia Bank'
];

const PartnersList = () => {
  const mappedPartners = partners.map((partnerName) => (
    <OwnUpBody variant="body1" key={partnerName}>
      {partnerName}
    </OwnUpBody>
  ));
  return <React.Fragment>{mappedPartners}</React.Fragment>;
};

const DividerLine = styled.hr`
  height: 1px;
  background-color: ${FOG_100};
  border: none;
  margin: 20px 0;
`;

// eslint-disable-next-line max-lines-per-function
export const Providers = () => (
  <LayoutWithAppWrapper>
    <PageWrapper>
      <ContentWrapper>
        <PageTitle variant="title">Providers</PageTitle>
        <OwnUpDefaultInfoNotification
          $hasIcon={true}
          description="The Own Up Network includes the following partners who may contact you via telephone or email."
        />
        <OwnUpSmallSubheadlineRegular variant="h1" style={{ marginTop: '40px' }}>
          Our Partners
        </OwnUpSmallSubheadlineRegular>
        <DividerLine />
        <PartnersList />
      </ContentWrapper>
    </PageWrapper>
  </LayoutWithAppWrapper>
);

export default Providers;
